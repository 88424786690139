import { Container, Row, Image } from 'react-bootstrap';

import "./Footer.css";


const Footer = (): JSX.Element => {
    return (
        <Container id='footer' fluid className='p-3 px-md-5 pt-md-5'>
            <Container>
                <div className='alt-text center'>
                    <h2>Contact</h2>
                </div>
                <div className='bar center' />
            </Container>
            <Container className='pt-sm-3'>
                <p className='center'>
                    Need to get in touch? Drop me a line at <a href="mailto:nffrangie@gmail.com" rel="noreferrer" target="_blank">nffrangie@gmail.com</a>
                </p>
                <p className='center'>
                    Why not check out my <a href="/pdf/Nicholas-Frangie-Resume.pdf" rel="noreferrer" target="_blank">resume</a> while you're here?
                </p>
            </Container>
            <Row id='contact-row' className='d-flex justify-content-center px-3'>
                <a href='mailto:nffrangie@gmail.edu' className='m-1 m-sm-3 m-md-4 p-0' rel="noreferrer" target="_blank">
                    <Image fluid className='contact-icon' src='/svg/mail.svg' alt='Email'/>
                </a>
                <a href='https://www.linkedin.com/in/nicholas-frangie/' className='m-1 m-sm-3 m-md-4 p-0' rel="noreferrer" target="_blank">
                    <Image fluid className='contact-icon' src='/svg/linkedin.svg' alt='LinkedIn'/>
                </a>
                <a href='https://github.com/NickFrangie' className='m-1 m-sm-3 m-md-4 p-0' rel="noreferrer" target="_blank">
                    <Image fluid className='contact-icon' src='/svg/github.svg' alt='GitHub'/>
                </a>
                <a href='https://bsky.app/profile/nicholasfrangie.com' className='m-1 m-sm-3 m-md-4 p-0' rel="noreferrer" target="_blank">
                    <Image fluid className='contact-icon' src='/svg/bluesky.svg' alt='Bluesky'/>
                </a>
            </Row>
        </Container>
    );
}

export { Footer };